import React, { useState, useEffect } from "react"
import SEO from "../components/seo"
import axios from "axios"

const style = {
  display: "flex",
  "align-items": "center",
  "justify-content": "center",
  "font-family": "-apple-system, sans-serif",
  "flex-direction": "column",
  height: "100vh",
}

const IndexPage = () => {
  const [status, setStatus] = useState({})

  useEffect(() => {
    axios.get("https://f.orum.co/_status").then(forumRes => {
      axios
        .get("https://status.heroku.com/api/v3/current-status")
        .then(herokuRes => {
          setStatus({
            doneFetching: true,
            status: forumRes.status,
            hash: forumRes.data,
            herokuRes: herokuRes.data,
          })
        })
    })
  }, [])

  return (
    <div style={style}>
      <SEO title="Forum Status" />
      <RenderStatus status={status} />
      {renderHerokuIssue(status.herokuRes)}
    </div>
  )
}

function RenderStatus({ status }) {
  if (!status.doneFetching) {
    return null
  }

  if (
    status.status === 200 &&
    status.hash.length > 0 &&
    status.herokuRes.status.Production === "green"
  ) {
    return <StatusUp />
  }
  if (status.status !== 200) {
    return <StatusDown />
  }

  return <StatusMeh />
}

function renderHerokuIssue(herokuRes) {
  if (!herokuRes) {
    return null
  }

  if (herokuRes.status.Production !== "green") {
    return (
      <div
        style={{
          margin: "2em 15% 0",
          border: "2px solid #bb9fff",
          borderRadius: "5px",
          padding: "1em",
        }}
      >
        <div style={{ marginBottom: "1em" }}>Potential Heroku issue:</div>
        <div>"{herokuRes.issues[0].updates[0].contents}"</div>
      </div>
    )
  }
}

const StatusUp = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <span
        style={{
          display: "block",
          width: "12px",
          height: "12px",
          backgroundColor: "rgb(77, 195, 16)",
          borderRadius: "100%",
          marginRight: "10px",
        }}
      />
      <span>The Forum is up.</span>
    </div>
  )
}

const StatusMeh = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <span
        style={{
          display: "block",
          width: "12px",
          height: "12px",
          backgroundColor: "rgb(251, 249, 19)",
          borderRadius: "100%",
          marginRight: "10px",
        }}
      />
      <span>The Forum may be experiencing issues.</span>
    </div>
  )
}

const StatusDown = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <span
        style={{
          display: "block",
          width: "12px",
          height: "12px",
          backgroundColor: "rgb(238, 60, 8)",
          borderRadius: "100%",
          marginRight: "10px",
        }}
      />
      <span>The Forum is down.</span>
    </div>
  )
}

export default IndexPage
